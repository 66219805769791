// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
#temp{
	color: #fff;
    background-color: #009cde;
    background-image: radial-gradient(circle farthest-side at center bottom, #009cde, #003087 125%);
    height: 100vh;
    position:relative;
    #logo{
    	background-image: url('/imgs/wundle_logo_rainbow_gap_white.svg');
    	background-repeat: no-repeat;
    	width: 20rem;
    	top:30vh;
    	position: absolute;
    	left:50%;
    	margin-left:-10rem;
    	height: 10rem;
    }
    #changes{
    	font-weight:400;
    	font-size:2rem;
    	text-align:center;
    	width: 40rem;
    	top:45vh;
    	position: absolute;
    	left:50%;
    	margin-left:-20rem;
    }
    #contact{
    	font-weight:400;
    	font-size:2rem;
    	text-align:center;
    	width: 50rem;
    	top:55vh;
    	position: absolute;
    	left:50%;
    	margin-left:-25rem;
    }
}


